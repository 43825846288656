<template>
  <Layout>
    <b-row class="pb-4">
      <b-col cols="6">
        <h2>Frota</h2>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button type="button" to="/admin/contracts">
          Contratos
        </b-button>
      </b-col>
    </b-row>
    <div v-show="getFleetPosition.length" class="animated fadeIn">
      <b-card body-class="p-0">
        <b-card-body class="p-0">
          <fleet-position-map
            :fleet="true"
            class="maps"
            name="fleet-location"
            :tracking="getFleetPosition"
          />
        </b-card-body>
      </b-card>
    </div>
    <div v-if="!$apollo.queries.cars.loading">
      <b-card body-class="p-0">
        <b-card-body class="animated fadeIn p-0">
          <b-table
            responsive
            striped
            hover
            :items="cars.items"
            :fields="fields"
            @row-clicked="goTo"
          >
            <template v-slot:cell(license_plate)="data">
              <strong>{{ data.item.license_plate }}</strong>
              <br>
              <span v-if="data.item.brand" class="small text-muted">
                <strong>{{ data.item.brand.name }}</strong>
                <span v-if="data.item.model">/ {{ data.item.model.name }}</span>
              </span>
              <span v-else class="small text-muted">Aguardando informações do DENATRAN...</span>
            </template>
            <template v-slot:cell(booking)="data">
              <span v-if="data.item.booking">
                <strong>Alugado</strong>
                <br>
                <span class="small text-muted">{{ data.item.booking.driver.name }}</span>
              </span>
              <span v-else class="text-muted">
                <strong>Pátio</strong>
                <br>
                <span class="small text-muted">{{ data.item.place.name }}</span>
              </span>
            </template>
            <template v-slot:cell(tracking)="data">
              <span class="small text-provider">
                  Monitorado por
                  {{ data.item.provider }}
                </span>
              <span v-if="data.item.tracking">
                {{ getTrackingStatus(data.item.tracking) }}
                <br>
                <span class="small text-muted">
                  Atualizado
                  {{ getTrackingLastSync(data.item.tracking) | moment('from', true) }} atrás
                </span>
              </span>
              <span v-else>Sem dados</span>
            </template>
            <template v-slot:cell(status)="data">
              <b-badge :variant="setStatusBadge(data.item.status)">
                {{ data.item.status }}
              </b-badge>
            </template>
            <template v-slot:cell(edit)="data">
              <b-button title="Editar veículo" variant="link" @click.stop="goTo(data.item, true)">
                <i class="fa fa-edit" />
              </b-button>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="cars.errorType || cars.items.length === 0" class="p-0">
          <content-controll :service="cars" />
        </b-card-body>
      </b-card>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
    <b-pagination
      v-model="pagination.current"
      align="center"
      size="md"
      :total-rows="cars.count"
      :per-page="pagination.total"
    />
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import FleetPositionMap from '@components/fleet-position-map';
import { formatStatus } from '@utils/car';

import CAR_LIST from '@graphql/car/queries/list.gql';
import CAR_LIST_TRACKING from '@graphql/car/queries/tracking-list.gql';

export default {
  name: 'Fleet',
  page: {
    title: 'Frota',
  },
  components: {
    Layout,
    ContentControll,
    ContentLoading,
    FleetPositionMap,
  },
  data: () => {
    return {
      fields: [
        {
          key: 'license_plate',
          label: 'Placa',
        },
        {
          key: 'booking',
          label: 'Status',
        },
        {
          key: 'tracking',
          label: 'Status tracking',
        },
        {
          key: 'status',
          label: '&nbsp;',
          class: 'text-right',
        },
        {
          key: 'edit',
          label: '&nbsp;',
          class: 'edit-line',
        },
      ],
      cars: {
        count: 0,
        items: [],
      },
      pagination: {
        current: 1,
        total: 10,
      },
      tracking: [],
      timer: 0,
    };
  },
  apollo: {
    cars: {
      query: CAR_LIST,
      pollInterval: 120000,
      variables() {
        return {
          page: this.pagination.current - 1,
          limit: this.pagination.total,
        };
      },
    },
    tracking: {
      query: CAR_LIST_TRACKING,
      pollInterval: 30000,
      update: data => data.carsTracking,
    },
  },
  computed: {
    getFleetPosition() {
      return this.tracking
        .map(data => {
          if (
            data.car &&
            data.car.id &&
            data.position.location[0] !== 0 &&
            data.position.location[1] !== 0
          ) {
            return {
              id: data.car.id,
              battery: data.battery,
              speed: data.speed,
              odometer: data.odometer,
              engine: data.engine,
              updated_at: data.updated_at,
              position: data.position,
            };
          }

          return null;
        })
        .filter(data => !!data);
    },
  },
  watch: {
    cars(newValue) {
      this.getFleetAsPackage(newValue.count);

      this.timer = setInterval(() => {
        this.getFleetAsPackage(newValue.count);
      }, 30000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    setStatusBadge(status) {
      return formatStatus(status);
    },
    goTo(_item = null, _edit = false) {
      if (_edit === true) {
        this.$router.push({
          path: '/fleet/' + _item.id + (_edit ? '/edit' : ''),
        });
      } else {
        this.$router.push({
          path: '/fleet/' + _item.id,
        });
      }
    },
    getFleet(page, limit) {
      return this.$apollo.query({
        query: CAR_LIST_TRACKING,
        fetchPolicy: 'no-cache',
        variables: {
          page,
          limit,
        },
      });
    },
    getFleetAsPackage(total) {
      if (total > 200) {
        const packNumber = 6;

        let init = 0;
        let pack = parseInt(total / packNumber);
        let fleet = [];

        for (let i = 0; i < packNumber; i++) {
          fleet.push(this.getFleet(init, pack));
          init += pack;
        }

        Promise.all(fleet).then(result => {
          this.tracking = [];
          result.forEach(pack => {
            this.tracking = [...this.tracking, ...pack.data.carsTracking];
          });
        });
      } else {
        this.getFleet(0, total).then(
          result => (this.tracking = result.data.carsTracking)
        );
      }
    },
    getTrackingLastSync(tracking) {
      if (tracking) {
        return this.$moment(tracking.updated_at);
      } else {
        return '';
      }
    },
    getTrackingStatus(tracking) {
      let _message = 'Sem dados';
      if (tracking) {
        // Check if sensor is shutting down
        const check_diff = this.$moment()
          .utc()
          .diff(this.$moment(tracking.updated_at).utc(), 'minutes');

        if (parseFloat(tracking.battery.car) === 0) {
          _message = 'Rastreador desconectado!!!';
        } else if (parseFloat(tracking.battery.sensor) <= 2) {
          _message =
            (tracking.engine === 'true' ? 'Motor ligado' : 'Motor desligado') +
            ': Bateria baixa do rastreador';
        } else if (tracking.engine === 'true' && check_diff >= 30) {
          _message =
            'Motor ligado: Sem status a mais de ' + check_diff + ' minutos';
        } else if (tracking.engine === 'false' && check_diff >= 50) {
          _message =
            'Motor desligado: Sem status a mais de ' + check_diff + ' minutos';
        } else {
          _message =
            (tracking.engine === 'true' ? 'Motor ligado' : 'Motor desligado') +
            ': Tudo normal';
        }
        return _message;
      } else {
        return _message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.maps {
  border-radius: 0.25rem;
  height: 350px;
}
</style>
