var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [_c("h2", [_vm._v("Frota")])]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "6" } },
            [
              _c(
                "b-button",
                { attrs: { type: "button", to: "/admin/contracts" } },
                [_vm._v(" Contratos ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.getFleetPosition.length,
              expression: "getFleetPosition.length",
            },
          ],
          staticClass: "animated fadeIn",
        },
        [
          _c(
            "b-card",
            { attrs: { "body-class": "p-0" } },
            [
              _c(
                "b-card-body",
                { staticClass: "p-0" },
                [
                  _c("fleet-position-map", {
                    staticClass: "maps",
                    attrs: {
                      fleet: true,
                      name: "fleet-location",
                      tracking: _vm.getFleetPosition,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.$apollo.queries.cars.loading
        ? _c(
            "div",
            [
              _c(
                "b-card",
                { attrs: { "body-class": "p-0" } },
                [
                  _c(
                    "b-card-body",
                    { staticClass: "animated fadeIn p-0" },
                    [
                      _c("b-table", {
                        attrs: {
                          responsive: "",
                          striped: "",
                          hover: "",
                          items: _vm.cars.items,
                          fields: _vm.fields,
                        },
                        on: { "row-clicked": _vm.goTo },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(license_plate)",
                              fn: function (data) {
                                return [
                                  _c("strong", [
                                    _vm._v(_vm._s(data.item.license_plate)),
                                  ]),
                                  _c("br"),
                                  data.item.brand
                                    ? _c(
                                        "span",
                                        { staticClass: "small text-muted" },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(data.item.brand.name)
                                            ),
                                          ]),
                                          data.item.model
                                            ? _c("span", [
                                                _vm._v(
                                                  "/ " +
                                                    _vm._s(data.item.model.name)
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "small text-muted" },
                                        [
                                          _vm._v(
                                            "Aguardando informações do DENATRAN..."
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                            {
                              key: "cell(booking)",
                              fn: function (data) {
                                return [
                                  data.item.booking
                                    ? _c("span", [
                                        _c("strong", [_vm._v("Alugado")]),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "small text-muted" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.item.booking.driver.name
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c(
                                        "span",
                                        { staticClass: "text-muted" },
                                        [
                                          _c("strong", [_vm._v("Pátio")]),
                                          _c("br"),
                                          _c(
                                            "span",
                                            { staticClass: "small text-muted" },
                                            [
                                              _vm._v(
                                                _vm._s(data.item.place.name)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                            {
                              key: "cell(tracking)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "small text-provider" },
                                    [
                                      _vm._v(
                                        " Monitorado por " +
                                          _vm._s(data.item.provider) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  data.item.tracking
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getTrackingStatus(
                                                data.item.tracking
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "small text-muted" },
                                          [
                                            _vm._v(
                                              " Atualizado " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.getTrackingLastSync(
                                                      data.item.tracking
                                                    ),
                                                    "from",
                                                    true
                                                  )
                                                ) +
                                                " atrás "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c("span", [_vm._v("Sem dados")]),
                                ]
                              },
                            },
                            {
                              key: "cell(status)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        variant: _vm.setStatusBadge(
                                          data.item.status
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(edit)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        title: "Editar veículo",
                                        variant: "link",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.goTo(data.item, true)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-edit" })]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2519388060
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm.cars.errorType || _vm.cars.items.length === 0
                    ? _c(
                        "b-card-body",
                        { staticClass: "p-0" },
                        [
                          _c("content-controll", {
                            attrs: { service: _vm.cars },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
      _c("b-pagination", {
        attrs: {
          align: "center",
          size: "md",
          "total-rows": _vm.cars.count,
          "per-page": _vm.pagination.total,
        },
        model: {
          value: _vm.pagination.current,
          callback: function ($$v) {
            _vm.$set(_vm.pagination, "current", $$v)
          },
          expression: "pagination.current",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }